import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { PageProps } from "gatsby"
import Section from "../components/Section"
import Wrapper from "../components/Wrapper"
import ErrorPage from "../assets/errorPage.svg"
import SEO from "../components/SEO"
export default function Impressum(props: PageProps) {
  return (
    <React.Fragment>
      <SEO titleAddition="Fehler" />
      <Header />
      <div className="w-full overflow-x-hidden flex flex-col  text-gray-700">
        <Section>
          <Wrapper>
            <div
              className="p-8 sm:p-16 flex flex-col items-center justify-center"
              css={{
                minHeight: "calc(100vh - 5rem)",
              }}
            >
              <div className="w-full md:w-3/4 lg:w-1/2">
                <ErrorPage />
              </div>
              <div className="mt-8 text-lg sm:text-3xl font-bold">
                Diese Seite existiert nicht!
              </div>
            </div>
          </Wrapper>
        </Section>
        <Section>
          <Wrapper>
            <Footer />
          </Wrapper>
        </Section>
      </div>
    </React.Fragment>
  )
}
